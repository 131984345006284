<template>
	<v-layout fill-height column>
		<v-flex shrink>
			<v-layout row justify-end align-center>
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-btn flat round small color="primary" v-on="on" @click="addDocument">
							<v-icon>add</v-icon>
							<span v-t="'client_workspace.task.add'" class="mx-2" />
						</v-btn>
					</template>
					<span v-t="'client_workspace.task.addAttachment'" />
				</v-tooltip>
			</v-layout>
		</v-flex>
		<v-flex fill-height scroll-y>
			<v-list>
				<v-list-tile v-if="loading">
					<v-layout align-center justify-center>
						<v-progress-circular color="primary" indeterminate />
					</v-layout>
				</v-list-tile>
				<v-list-tile v-for="document in attachments" :key="document.id" @click.stop="openDocument(document)">
					<v-list-tile-avatar tile>
						<NodeItem :value="document" small :medium="false" :show-thumbnail="true" :show-counter="false" :show-title="false" :show-validation="false" />
					</v-list-tile-avatar>
					<v-list-tile-content>
						<v-list-tile-title>
							<v-layout row justify-center align-center>
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<v-flex xs3 text-truncate v-on="on" v-text="document.name" />
									</template>
									<span v-text="document.name" />
								</v-tooltip>
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<v-flex xs7 mx-1 text-truncate v-on="on" v-text="document.path" />
									</template>
									<span v-text="document.path" />
								</v-tooltip>
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<v-flex text-truncate xs2 v-on="on" v-text="$d(new Date(document.created_at.replace(/-/g, '/')))" />
									</template>
									<span v-text="$d(new Date(document.created_at.replace(/-/g, '/')))" />
								</v-tooltip>
							</v-layout>
						</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>
			</v-list>
		</v-flex>
		<TasksManagerActionAddAttachmentsDialog v-if="showAddAttachments" :task="task" @close="closeAddAttachmentsAndReload()" />
		<DocumentPreview v-if="showPreview" :is-editable="false" @close="closePreview()" />
	</v-layout>
</template>

<script>
import { mapMutations } from "vuex";
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'
import DocumentEditionService from '@/services/Documents/DocumentEditionService'
import DocumentVersionsService from '@/services/Documents/DocumentVersionsService'
import { Document } from '@/classes/Documents/Document.class'
import { isViewable } from "@/helpers/files";

export default {
	name: 'TasksManagerTaskDetailsAttachmentList',
	components: {
		TasksManagerActionAddAttachmentsDialog: () => ({
			component: import('@/components/TasksManager/Actions/Attachments/TasksManagerActionAddAttachmentsDialog')
		}),
		DocumentPreview: () => ({
			component: import('@/components/Documents/Preview/DocumentPreview')
		}),
		NodeItem: () => ({
			component: import('@/components/Documents/Content/NodeItem')
		})
	},
	mixins: [TasksManagerModuleGuard],
	props: {
		task: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			attachments: [],
			loading: false,
			showAddAttachments: false,
			showPreview: false,
		}
	},
	watch: {
		'task.id': {
			handler: function (taskId) {
				if (taskId) {
					this.loadAttachments()
				}
			}
		}
	},
	mounted: function () {
		if (this.task?.id) {
			this.loadAttachments()
		}
	},
	methods: {
		...mapMutations({
			setCurrent: 'documents/preview/setCurrent',
		}),
		loadAttachments: function () {
			this.loading = true

			this.service
				.listAttachments(this.accountingFirmId, this.task.vendor.id, this.task.id)
				.then(attachments => {
					this.attachments.splice(0, this.attachments.length)
					attachments.forEach(attachment => {
						this.attachments.push(new Document(attachment))
					})
				})
				.finally(() => {
					this.loading = false
				})
		},
		addDocument: function () {
			this.showAddAttachments = true
		},
		openDocument: function (document) {
			if (this.isViewable(document)) {
				if (this.isEditable(document)) {
					DocumentVersionsService.getDocumentVersions(document.vendor_id, document).then(versions => {
						let documentToCheck = Object.assign({}, document)
						documentToCheck.latestVersion = versions[0]
						DocumentEditionService.getDocumentEditionLaunchingOptions(this.vendorId, documentToCheck).then(({ local, url }) => {
							if (local) {
								window.open(url, '_blank')
							} else {
								this.openPreview(document)
							}
						})
					})
				} else {
					this.openPreview(document)
				}
			}
		},
		openPreview: function (document) {
			this.setCurrent(document)
			this.showPreview = true
		},
		closePreview: function () {
			this.showPreview = null
		},
		isViewable: function (currentDocument) {
			return currentDocument && isViewable(currentDocument.type)
		},
		isEditable: function (currentDocument) {
			return this.appService.hasModule('document-edition') && DocumentEditionService.isDocumentEditable(currentDocument)
		},
		closeAddAttachmentsAndReload: function ()
		{
			this.showAddAttachments = false
			this.loadAttachments()
		}
	}
}
</script>
